import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Cross({ size, color, css }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      css={css}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle d="M2.2 12H21.7" stroke={color} strokeRound />
      <BaseIconPathStyle d="M12 2.2V21.7" stroke={color} strokeRound />
    </BaseSvgStyle>
  );
}

export default Cross;
